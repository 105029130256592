.details-hero-sec {
    position: relative;
    margin-top: 80px;
    background-image: url('../../assets/images/details\ background.svg');
    background-repeat: no-repeat;
    height: calc(100vh - 80px);
    background-size: cover
}

@media screen and (max-width: 900px) {
    .details-hero-sec {
        background-image: url('../../assets/images/details\ hero.svg');
    }
}