/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cairo', sans-serif !important;
}

body {
  direction: rtl;
}


a {
  text-decoration: none;
}

a.activeLink {
  /* border-bottom: 3px solid #000; */
  font-weight: bold;
}

.container {
  padding: 70px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.color-primary {
  color: #F79E02 !important;
}

.btn-primary,
.bg-primary {
  background-color: #F79E02 !important;
  color: #000 !important;
}

.bg-secondary {
  background-color: #FBFBFB;
}

.nav-link {
  color: black;
  text-decoration: none;
  /* margin-right: 16px !important; */
}

.nav-btn {
  border: 1px solid black !important;
  color: black !important;
  /* margin-right: 16px !important; */
}

.nav-btn.activeBtn {
  background-color: #000;
  color: #fff !important;
}

.nav-btn:hover {
  border-color: black !important;
  background-color: black !important;
  color: white !important;
}

.card {
  width: 100%;
  /* height: 400px; */
  background-color: white;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transition-duration: .2s;
}

.card:hover {
  box-shadow: 0px 4px 15px 0px rgba(247, 158, 2, 0.50);
  /* margin-top: -20px; */
}

.support-icon {
  background-color: #F79E02 !important;
  color: #000 !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  width: 50px;
  height: 50px;
}

.gallery-container {
  padding: 100px 70px;
}

.navbar-hidden-desctop {
  display: flex;
  flex-direction: row;
  gap: 16px
}


@media screen and (max-width: 900px) {
  .container {
    padding: 24px;
  }

  .hero-sec {
    align-items: center;
    margin-bottom: 150px;
  }

  .text-box {
    position: absolute;
    bottom: -120px;
  }

  .gallery-container {
    padding: 100px 24px;
  }

  .navbar-hidden-desctop {
    display: none;
  }
}