.hero-sec {
    position: relative;
    margin-top: 80px;
    background-image: url('../../assets/images/hero.jpg');
    background-repeat: no-repeat;
    height: calc(100vh - 80px);
    background-position-x: center;
    background-size: cover
}

.text-box {
    text-align: center;
    width: 400px;
    max-width: 90%;
    min-height: 400px;
    padding: 32px;
    border-radius: 20px;
    background: rgba(16, 59, 117, 0.80);
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.50);
    color: white;
}

.text-box h2 {
    color: #F79E02;
    text-align: center;
}

.home-btn:hover a {
    color: #FFF !important;
}

.home-btn {
    border-radius: 10px;
    background: #FFF !important;
    box-shadow: 0px 0px 15px 0px #F79E02;
    margin-top: 24px !important;
    color: #F79E02 !important;
}

.home-btn:hover {
    background-color: #F79E02 !important;
    color: #fff !important;
}

.sub-heading {
    color: #F79E02;
}

.main-heading {
    font-size: 45px;
    font-weight: 700;
    line-height: 60px;
    margin: 16px 0;
}





@media screen and (max-width: 900px) {
    .hero-sec {
        background-position-x: 65%;
    }

    h2 {
        font-size: 24px !important;
        line-height: 30px !important;
    }

    .best-recommendation-sec h2 {
        font-size: 20px !important;
    }

    .sub-heading {
        text-align: center;
    }

    .about-sec img {
        margin-top: 24px;
    }
}