.video-gallery {
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
}

.video {
    padding: 0 12px 12px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.video .video-container {
    cursor: pointer;
    position: relative;
    /* height: 200px; */
}

.video .video-container video {
    object-fit: cover;
    max-height: 100%;
}

.imgg {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.gallery-menu-items {
    padding: 10px 0;
    margin: 16px 0;
    border-bottom: 1px solid lightgray;
    display: flex;
    gap: 16px;
}

.active-gallery-btn {
    background-color: #F79E02 !important;
}

.gallery-btn {
    margin: 0 16px;
    border-color: #F79E02 !important;
    color: #F79E02 !important;
}

.active-gallery-btn:hover {
    background-color: #f0ac38 !important;
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    box-shadow: 0lh;
}

.video .title {
    margin-bottom: 6px;
}

.rh5v-Overlay_component {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rh5v-Overlay_inner {
    position: initial;
    transform: none;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}

.rh5v-Overlay_icon {
    position: initial;
    transform: none;
    margin-left: 0;
}

.main-modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    z-index: 2001;
    padding: 100px;
}

.main-modal .video-container {
    width: auto;
    max-width: 75%;
    height: auto;
    max-height: 110%;
}

.main-modal .modal-close-btn {
    position: fixed;
    top: 24px;
    right: 24px;
    background-color: transparent;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: none;
    z-index: 3000;
}


.gallery-images-container {
    padding-bottom: 32px;
}

@media screen and (max-width: 900px) {
    .main-modal {
        padding: 24px;
    }

    .video-gallery {
        grid-template-columns: repeat(2, 1fr);
    }

    .main-modal .video-container {
        max-width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .video-gallery {
        grid-template-columns: repeat(1, 1fr);
    }

    .video .video-container {
        height: 180px;
    }

}