input,
textarea {
    padding: 12px;
    outline-color: #F79E02;
    resize: none;
}

.contact-page {
    padding-top: 130px;
}


@media screen and (max-width: 900px) {
    .contact-page {
        padding-top: 100px;
    }
}